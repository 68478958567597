import { useMemo } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { useCurrentLang } from "../hooks/useLocalizedCartInfo";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import useDeviceDetect from "../hooks/useDeviceDetect";

import { Link } from "./al_components/ALLink";

import * as Styles from "./lookingFor/styles.module.scss";

const sortOrder = [
  "/collections/earrings",
  "/collections/necklaces",
  "/collections/rings",
  "/collections/bracelets",
  "/collections/fine-jewelry",
];

function LookingFor({ title, hasBorderTop = false, noDesktopTop = false }) {
  const { isMobile } = useDeviceDetect();
  const dict = useLocalizedSentenceDict();
  const lang = useCurrentLang();

  const data = useStaticQuery(graphql`
    query lookingFor {
      lookingFor: allContentfulLookingForSomethingElse {
        nodes {
          node_locale
          title
          imagesLookingFor {
            desktopImage {
              gatsbyImageData
            }
            mobileImage {
              gatsbyImageData(width: 60)
            }
            link {
              link {
                link
              }
              title
            }
            altText
          }
        }
      }
    }
  `);

  const sortedData = useMemo(() => {
    if (!data) {
      return [];
    }

    let localeData = data?.lookingFor?.nodes.find((e) => e.node_locale === lang);
    if (!localeData) {
      localeData = data?.lookingFor?.nodes.find((e) => e.node_locale === "en-US");
    }

    const sorted = [];
    sortOrder.forEach((e) => {
      const item = localeData.imagesLookingFor.find((f) => {
        const formattedLink = f.link.link.link.replace(/\/$/, ""); // Remove trailing slash
        const formattedE = e.replace(/\/$/, ""); // Remove trailing slash
        return formattedLink === formattedE;
      });
      if (item) {
        sorted.push({ ...item, title: dict.get(item.link.title) });
      }
    });
    return sorted;
  }, [dict, data, lang]);

  const localeTitle = useMemo(() => {
    let localeData = data?.lookingFor?.nodes.find((e) => e.node_locale === lang);
    if (!localeData) {
      localeData = data?.lookingFor?.nodes.find((e) => e.node_locale === "en-US");
    }
    return localeData?.title;
  }, [data, lang]);

  if (sortedData.length === 0) {
    return null;
  }

  return (
    <div
      className={`${Styles.container} ${hasBorderTop ? Styles.containerBordered : ""} ${
        noDesktopTop ? Styles.containerNoDesktopTop : ""
      }`}
    >
      <h2
        className={
          isMobile ? "h2 h2--bold h2--uc m-0 mb-20 ml-5 mr-5" : "h3 h3--bold h3--uc m-0 mb-25"
        }
      >
        {title || localeTitle}
      </h2>
      <div className={Styles.content}>
        {sortedData.map((item) => (
          <Link
            key={`looking_for_${item.link.link.link}`}
            className={Styles.content_link}
            to={item.link.link.link}
          >
            <div className={Styles.content_image_container}>
              <GatsbyImage
                className={Styles.content_image_container_img}
                image={
                  isMobile
                    ? getImage(item.mobileImage.gatsbyImageData)
                    : getImage(item.desktopImage.gatsbyImageData)
                }
                alt={item.altText}
              />
            </div>

            <h3 className={isMobile ? "microtext microtext--uc mt-5" : "h4 mt-15 mb-0"}>
              {item.title}
            </h3>
          </Link>
        ))}
      </div>
    </div>
  );
}

LookingFor.propTypes = {
  title: PropTypes.string,
  hasBorderTop: PropTypes.bool,
  noDesktopTop: PropTypes.bool,
};

export default LookingFor;
