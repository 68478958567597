import { useContext } from "react";
import { Helmet } from "react-helmet";

import { markdownFormat } from "../../context/helpers";
import { WishlistContext } from "../../context/wishlistProvider";
import { useAuth } from "../../context/AuthProvider";
import { useProductData2 } from "../../context/products";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import useIsHydrationComplete from "../../hooks/useIsHydrationComplete";

import SectionTitle from "../../components/alRewards/SectionTitle";
import LookingFor from "../../components/LookingFor";
import ProductCollection from "../../components/CollectionPage/ProductCollection";
import HeaderContainer from "../../components/alRewards/HeaderContainer";
import SEO from "../../components/seo";
import { ALButton, ALLoading } from "../../components/ALComponents";
import { Link } from "../../components/al_components/ALLink";
import { ComponentType } from "../../constants/ComponentType";

import * as wishlistStyles from "./wishlistStyles.module.scss";

function WishlistPage({ path }) {
  const hydrationComplete = useIsHydrationComplete();
  const dict = useLocalizedSentenceDict();
  const wishlistContext = useContext(WishlistContext);
  const { accountDetails, showLogin, showCreateAccount } = useAuth();

  const countryCode = useCurrentCountryCode();
  const { wishlistItems, isLoaded } = wishlistContext;

  const { data: products, isInitialLoading: isLoading } = useProductData2(
    wishlistItems,
    "long",
    countryCode
  );

  const wishlistProducts = products ?? [];
  const createAccount = markdownFormat(dict.get("_*Create an account*_ or"));
  const signIn = markdownFormat(dict.get("_*sign in*_ to save your favorites!"));

  if (!hydrationComplete) {
    return null;
  }

  return (
    <>
      {accountDetails && <HeaderContainer />}
      <div className={wishlistStyles.wishlist}>
        <SEO
          title="Wish list"
          description="Discover how to easily save your favorite items on your wishlist at Ana Luisa. Shop at your own pace and never miss out on the pieces you love. Start building your dream collection today!"
        />
        <Helmet>
          <link rel="canonical" href={`https://www.analuisa.com${path}`} />
          <meta property="og:url" content={`https://www.analuisa.com${path}`} />
        </Helmet>
        <div className={wishlistStyles.wishlist_container}>
          <div className={wishlistStyles.wishlist_header}>
            <SectionTitle>{dict.get("Wish list")}</SectionTitle>
            <p>
              {`${wishlistProducts.length} ${
                wishlistProducts.length === 1 ? dict.get("product") : dict.get("products")
              }`}
            </p>
          </div>

          {accountDetails && "email" in accountDetails && accountDetails.email != null ? null : (
            <div className={wishlistStyles.wishlist_account}>
              <button type="button" onClick={() => showCreateAccount()}>
                {createAccount}
              </button>{" "}
              <button type="button" onClick={() => showLogin()}>
                {signIn}
              </button>
            </div>
          )}
          <div className={wishlistStyles.wishlist_content}>
            {(isLoading || !isLoaded) && (
              <div>
                <ALLoading />
                <p className="al_h4">{`${dict.get("We are loading your products")}...`}</p>
              </div>
            )}
            {wishlistProducts.length > 0 && (
              <>
                {wishlistProducts.map((product, i) => (
                  <div
                    key={`wishlist-product-${i}`}
                    className={wishlistStyles.wishlist_content__product}
                  >
                    <ProductCollection
                      className="product_collection"
                      product={product}
                      collectionTitle="Wishlist"
                      componentType={ComponentType.WISHLIST}
                      cpPosition={i + 1}
                    />
                  </div>
                ))}
              </>
            )}
            {wishlistProducts.length === 0 && !isLoading && isLoaded && (
              <div className={wishlistStyles.wishlist_empty}>
                <p className={`${wishlistStyles.wishlist_empty_title} al_h3`}>
                  {dict.get("Your wish list is empty")}.
                </p>
                <Link to="/collections/bestsellers">
                  <ALButton variant="primary" fullWidth>
                    {dict.get("Best Sellers")}
                  </ALButton>
                </Link>
              </div>
            )}
          </div>
          <LookingFor title="CONTINUE SHOPPING" />
        </div>
      </div>
    </>
  );
}

export default withALErrorBoundary({
  name: "WishlistPage",
  priority: "P2",
})(WishlistPage);
